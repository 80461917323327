import dlink from "../";

// XZidPLZow1MrRAcJJuCra1cabPTKVxtfBO7

const _resize = [];

const errorMessages = {
	7003: "Pursuant to pCloud’s Terms of Service, access to this file has been disabled."
};

export default function error(data, holder) {
	const meta = data.metadata;

	let message = errorMessages[data.result] || data.error;

	let template = HFN.renderTemplate('#dLinkError', {
		error: message
	}).appendTo(holder);

	$(window).on('resize', resize).trigger('resize');
}

_resize.push(function () {
	dlink.centralizeDlContent('#dl_content .error');
})

function resize() {
	_resize.forEach(function (resize) {
		resize();
	});
}
