import dlink from '../';
import { getProperThumbnailSize, getVideoUrlsAndMaxVideoSize } from '../../../../src/containers/Gallery/utils';

var _resize = [];
var imageSize;
// var linkData;

function video(data, holder) {
	console.log("dlink video", data);
	var meta = data.metadata;
	// var maxWidth = Math.max(window.screen.availWidth, window.screen.availHeight);
	// var maxHeight = Math.max(window.screen.availWidth, window.screen.availHeight);
	var dynamicTitle;
	var template;

	// override

	if (dlink.code == 'XZJFuLZHB2zJ6b1CVJCTmCfv1iRPBBmwXr7') {
		data.country = 'sa';
	}

	// linkData = data;
	// imageSize = HFN.calcImageSize(meta.width || 600, meta.height || 480, maxWidth, maxHeight);

	template = HFN.renderTemplate('#dLinkVideo', {
		filename: meta.name,
		filesize: HFN.formatSize(meta.size)
	}).appendTo(holder);

	if ($.deparam.querystring().embed == 1) {
		template.addClass('embed');
	}

	//header title
	$('.header .filename').append(document.createTextNode(meta.name)).append(' <span>(' + HFN.formatSize(meta.size) + ')</span>');

	if (HFN.config.isMobile()) {
		if (!data.branding) {
			$('.header .filename').hide();
			// $('.header .bookmark').hide();
			// dynamic title
			dynamicTitle = dlink.dynamicTitle(meta);
			template.find('._title').append(dynamicTitle.template);
			_resize.push(dynamicTitle.resize);
		}

		dlink.addMobileButton(dlink.createShareButtonMobile());
		if (data.candownload ) {
			if (dlink._actionSheetDownloadItems().length !== 0) {
				dlink.addMobileButton(dlink.createDownloadButtonMobile());
			}
		}
		template.find('.button-area').remove();

		// checkPosterUrl(() => {
		// 	//_loadMobilePlayback(data, template.find('.player'), dlink.imageUrl(imageSize[0], imageSize[1]));
		// 	_loadPlayer(data, template.find('.player'), dlink.imageUrl(imageSize[0], imageSize[1]));
		// }, () => {
		// 	//_loadMobilePlayback(data, template.find('.player'), "");
		// 	_loadPlayer(data, template.find('.player'), "");
		// })
	} else {
		if (data.candownload) {
			dlink.addDesktopButton(dlink.getShareHeaderIcon());
			dlink.addDesktopButton(dlink.getDownloadHeaderButton());
		}

		// checkPosterUrl(() => {
		// 	_loadPlayer(data, template.find('.player'), dlink.imageUrl(imageSize[0], imageSize[1]));
		// }, () => {
		// 	_loadPlayer(data, template.find('.player'), "");
		// })
	}
	_loadVideoPlayer(data, template.find('.player')[0]);

	$(window).on('resize', resize);
	// setTimeout(resizeVideo, 0);

	/*
	if (linkData.country == 'sa' && !rcookie('pcauth')) {
		//_registerCryptoAd();
		//_resize.push(_registerCryptoAd);
		//

		template.append('<div class="ad"></div>');
		template.find('.ad').append(dlink.cryptoAd.get());

		//console.log('document', document.body);
		//console.log('video', template.find('.video'));
	}
	*/
}

export default video;

/**********************************/
/* 			  Helpers			  */
/**********************************/

function _loadVideoPlayer(data, node, autoPlay = false, seek = 0) {
	var videoData = {
		id: data.metadata.id,
		name: data.metadata.name,
		videoUrls: [],
		thumbUrl: "",
		thumbSize: "",//<width>x<height>
		thumbType: "image/jpeg",
		subUrl: "",
		maxWidth: 0,
		maxHeight: 0
	};

	var {videoUrls, videoMaxWidth, videoMaxHeight} = getVideoUrlsAndMaxVideoSize(data.variants, false);
	
	if (videoUrls.length === 0) {
		showErrorLoadingVideo();
		return;
	}

	videoData.videoUrls = videoUrls;
	videoData.maxWidth = videoMaxWidth;
	videoData.maxHeight = videoMaxHeight;
	
	var thumbWidth = getProperThumbnailSize(Math.min(videoData.maxWidth, Math.max(window.screen.availWidth, window.screen.availHeight)));
	var thumbHeight = getProperThumbnailSize(Math.min(videoData.maxHeight, Math.max(window.screen.availWidth, window.screen.availHeight)));
	videoData.thumbUrl = dlink.imageUrl(thumbWidth, thumbHeight);
	videoData.thumbSize = thumbWidth + "x" + thumbHeight;

	var videoContainerRemove = prc.VideoContainer(node, {
		ref: {},
		videoData,
		autoPlay,
		seek,
		noSwipeGesture: true,
		defaultDocumentTitle: document.title,
		setVideoLoading: toggleLoading,
		onPlay: () => {
			delete data.refreshedVideoLinks;
		},
		onError: ({autoPlay, mediaType, lastPlayingTime}) => {
			if (mediaType === "videoThumb") {
				// Broken video thumb. Keep showing the play button on black container.
				return;
			}

			if (data.refreshedVideoLinks) {
				showErrorLoadingVideo();
				return;
			}
			
			// Reload video links.
			toggleLoading(true);
			
			videoContainerRemove();
			HFN.apiMethod('getpubvideolinks', { code: dlink.code, linkpassword: dlink.linkpassword }, function (vlinksret) {
				data.refreshedVideoLinks = true;
				data.variants = vlinksret.variants;
				_loadVideoPlayer(data, node, autoPlay, lastPlayingTime);
			}, {
				errorCallback: function (ret) {
					showErrorLoadingVideo();
				}
			})
		}
	});

	return videoData;
}

function toggleLoading(loading) {
	if(loading) {
		$(".player").addClass("loading");
	} else {
		$(".player").removeClass("loading");
	}
}

function showErrorLoadingVideo() {
	$(".player").empty().append(HFN.renderTemplate('#dLinkVideoError'));
}

// @DEPRECATED
function padWidth() {
	var wpad = 0;

	if ($('.dl-sidebar').length && 0) {
		wpad += $('.dl-sidebar').outerWidth();
		wpad += 100;
	}

	return wpad;
}

// @DEPRECATED
function resizeVideo() {
	var hpad = 40;
	hpad += $('div.header-wrap').outerHeight();
	hpad += $('._title').outerHeight();
	//hpad += 50; // sa crypto stupid ads
	hpad += $('.button-area').outerHeight(); // download

	var wpad = padWidth();

	console.log('hpad', hpad);
	console.log('wpad', wpad);
	console.log('outerheight', $(window).outerHeight());

	var size = HFN.calcImageSize(imageSize[0], imageSize[1], $(window).outerWidth() - wpad, $(window).outerHeight() - hpad);
	var cssobj = {
		width: size[0],
		height: Math.max(200, size[1])
	};

	$('.player').css(cssobj);
	$('.play-video img').css(cssobj);

	dlink.centralizeDlContent('#dl_content .video');
	//dlink.centralizeDlContent('#dl_content .player');
}

// resize video
// _resize.push(resizeVideo);
// @DEPRECATED
function _genVideoSources(data) {
	var variants = data.variants;
	var downloadlink = data.downloadlink;
	var meta = data.metadata;
	var videoUrls = [];

	console.log('CODE', data.code);

	if (variants.length > 1) {
		videoUrls.push({
			quality: 'SD',
			id: 'normal',
			url: vLinks.getLowestUrl(variants),
			type: 'video/mp4',
			size: vLinks.size(vLinks.getLowest(variants)),
			res: vLinks.getLowest(variants).videobitrate
		});

		if (/*!$.browser.mobile && */variants.length > 2) {
			videoUrls.push({
				quality: 'HD',
				url: vLinks.getBestUrl(variants),
				id: 'hd',
				type: 'video/mp4',
				size: vLinks.size(vLinks.getBest(variants)),
				res: vLinks.getBest(variants).videobitrate
			});
		}

		if (
		  meta.videocodec &&
		  meta.videocodec == "h264" &&
		  "audiocodec" in meta &&
		  (meta.audiocodec == "aac" || meta.audiocodec == "") &&
		  fileext(meta.name) === "mp4"
		) {
		  videoUrls.push({
		    quality: "Original",
		    //url: downloadlink,
		    url: vLinks.getOriginalUrl(variants),
		    id: "original",
		    type: "video/mp4",
		    size: data.metadata.size,
		    res: vLinks.getOriginal(variants).videobitrate
		  });
		}


	} else {
		videoUrls.push({
			quality: 'Original',
			//url: downloadlink,
			url: vLinks.getOriginalUrl(variants),
			id: 'original',
			type: 'video/mp4',
			size: data.metadata.size,
			res: vLinks.getOriginal(variants).videobitrate
		});
	}

	console.log('video urls', videoUrls);

	return videoUrls;
}

// @DEPRECATED
function _registerCryptoAd() {
	alert('crypto ad');
}

// @DEPRECATED
function checkPosterUrl(successCallback, errorCallback) {
	var url = dlink.imageUrl(imageSize[0], imageSize[1]);
	dlink.checkImageUrl(url, successCallback, errorCallback);
}

// @DEPRECATED
function _loadPlayer(data, selector, posterUrl) {
	var videoUrls = _genVideoSources(data);
	var playerOpts = {
		selector: selector,
		poster: posterUrl,
		type: 'video',
		autoPlay: false,
		media: videoUrls,
		sources: videoUrls,
		//name: vidid,
		width: '100%',
		height: '100%',
		onReady: function(player) {

		},
		onError: function(e) {
			console.log('video read error', e.type);
			if (!(e.type.indexOf("stalled") !== false && $.browser.safari)) {
				$(selector).empty().append(HFN.renderTemplate('#dLinkVideoError'));
			}
		},
		onErrorCallback: function(message) {
			$(selector).empty().append(HFN.renderTemplate('#dLinkVideoError'));
		}
	};

	if (!rcookie('pcauth') && 0) {
		$('.player-ad').empty().append(
			$('<img />', {
				width: 300,
				height: 250,
				src: CDNVER + '/img/mobile/ads-2016/video-in-player.png'
			})
			.on('click', function() {
				HFN.initLoginRegModal('', function() {
					HFN.dLink._actionCopyToCloud();
				}, 'register');
			})
		);

		$.extend(playerOpts, {
			onPause: function() {
				console.log('pause');
				$('.player-ad').show();
			},
			onPlay: function() {
				console.log('play');
				$('.player-ad').hide();
			}
		});
	}
	console.log('player opts', playerOpts);

	HFN.player.loadVideo(playerOpts);
}

// @DEPRECATED
function _loadMobilePlayback(data, selector, imageUrl) {
	var play = $('<div class="play-video"></div>');
	var videoUrls = _genVideoSources(data);

	play.append($('<img>').attr('src', imageUrl))
		.append('<div class="play-button"></div>');

	if (videoUrls.length > 1) {

		/*
		actionSheet.setup({
			el: play,
			actionTitle: 'Download',
			items: videoUrls.map(function(item) {
				return {
					action: item.quality,
					title: item.quality + ' Quality',
					icon: 'icon-' + item.quality.toLowerCase()
				}
			})
		});
		*/

		console.log(videoUrls);

    actionSheet.setup({
        el: play,
        actionTitle: 'Choose version',
        items: videoUrls.map(function(item) {
        	return {
        		url: item.url,
        		id: item.id,
        		label: item.quality.toLowerCase(),
        		title: item.quality + ' (' + HFN.formatSize(item.size) + ')'
        	}
        }),
        onClick: function (item) {
            location.href = item.url;
        },
        buildItem: function (item, obj) {
            console.log(item, obj);

            item.append($('<span class="title"></span>').text(obj.title));

            if (!obj.isaction && !obj.iscancel)
                item.append($('<span class="sheet-icon sheet-icon-download"></span>').addClass(obj.id));

            if (obj.active != undefined && !obj.active)
                item.addClass('inactive');
        }
    });

	} else {
		play.on('click', function() {
			location.href = videoUrls[0].url;
		});
	}

	$(selector).append(play);
	resizeVideo();
}

function resize() {
	new DelayedExecution(function () {
		_resize.forEach(function (eachResize) {
			eachResize();
		});
	}, 80, 'img-resize');
}
