import dlink from "../";
import { isSameLocation, rcookie } from "../../utils";

// XZidPLZow1MrRAcJJuCra1cabPTKVxtfBO7

var _resize = [];

export default function overtraffic(data, holder) {
	console.log("dlink overtraffic");
	var meta = data.metadata;
	var dynamicTitle = dlink.dynamicTitle(meta);
	var template;

	template = HFN.renderTemplate('#dLinkOvertraffic2', {
		filename: meta.name,
		filesize: HFN.formatSize(meta.isfolder ? HFN.folderData(meta)[0] : meta.size),
		access_limit: __("access_limit", "Access to '%filename%' was limited due to high download traffic.", { filename: meta.name }),
		over_explain_premium: __("over_explain_premium", "You can still download the content of this link - get Premium plan or higher. %click_to_continue%", { click_to_continue: '<a href="javascript:;" class="go_plan">' + __("click_to_continue", "Click to continue") + '.</a>' }),
		over_explain_login: __("over_explain_login", "If you are already a Premium user, %click_to_login%", { click_to_login: '<a href="javascript:;" class="go_login">' + __("click_to_login", "click to login") + '</a>' })
	}, { escape: false }).appendTo(holder);

	// dynamic title
	template.find('._title').append(dynamicTitle.template);
	_resize.unshift(dynamicTitle.resize);

	var iconwrap = $('<div class="iconwrap"></div>');
	var icon = HFN.createIcon(HFN.metaIcon(meta), HFN.ICONS.OVERTRAFFIC_PUBLINK);

	iconwrap.append(icon);
	template.find('.icon').append(iconwrap);
	if (meta.thumb) {
		console.log('go now', dlink.code);
		setupThumb(icon, meta, HFN.ICONS.OVERTRAFFIC_PUBLINK, { code: dlink.code });

		if (meta.category == HFN.CATEGORY.VIDEO) {
			iconwrap.append($('<span class="overlay"></span><div class="infoicon"><img src="' +  retinaSrc('/ZCDNURL/img/small-play.png')+ '" width=10 /></div>'));
		} else if (meta.category == HFN.CATEGORY.AUDIO) {
			iconwrap.append('<div class="infoicon"><img src="/ZCDNURL/img/nota-standart.png" width="15"></div>');
		}
	}

	if (HFN.config.isMobile()) {
		template.find('.button-area').append(dlink.getMobileSaveButton());
		//dlink.addMobileButton(dlink.createDownloadButtonMobile());
	}  else if (data.candownload) {
		if (isSameLocation()) {
			template.find('.button-area').append(dlink.getSaveButton());
		}
		template.find('.button-area').append(dlink.getDownloadButton());

		// no share for overtraffic link?
		//dlink.addDesktopButton(dlink.getShareHeaderIcon());
	}

	$('.button-area .greenbut').addClass('greendisabled').off('click');
	$(window).on('resize', resize).trigger('resize');

	if (rcookie('pcauth')) {
		template.find('.not_logged').remove();
	}

	template.find('.go_plan')
		.attr('href', 'https://www.pcloud.com/cloud-storage-pricing-plans.html?period=year&returnurl=' + encodeURIComponent(location.href))
	template.find('.go_login').on('click', function() {
		HFN.initDownloadlinkLoginRegModal(function(userinfo) {
			location.reload();
		});
	});
}

_resize.push(function () {
	dlink.centralizeDlContent('#dl_content .generic');
})

function resize() {
	_resize.forEach(function (_func) {
		_func();
	});
}
