import dlink from "../";

// kZrNcHZ6O4qD1HGEY5kDn03E4dW8HypG0fV

var meta;
var code;
var linkpassword;
var canupload;
var usercanupload;
var candownload;
var template;
var grid_opts;

export default function folder(data, holder) {
	console.log("dlink folder", data);

	code = dlink.code;
	linkpassword = dlink.data.linkpassword;
	canupload = data.canupload;
	candownload = data.candownload;
	usercanupload = data.usercanupload;

	// init
	meta = data.metadata;
	template = HFN.renderTemplate('#dLinkFolder', {}).appendTo(holder);

	/*
	var filtered = HFN._filterTree(meta.contents);
	if (filtered.length)
		meta.contents = filtered;
	*/

	_filterTree(meta);

	HFN.cacheTree(meta, 'public', {});

	//header title
	$('.header .filename').append(document.createTextNode(meta.name));

	// setup buttons
	if (HFN.config.isMobile()) {
		if (data.candownload) {
			dlink.addMobileButton(dlink.createShareButtonMobile());
			if (dlink._actionSheetDownloadItems().length !== 0) {
				dlink.addMobileButton(dlink.createDownloadButtonMobile());
			}
		}
		// hide upload button for virtual folders
		if (meta.folderid !== 0 && data.canupload) {
			dlink.addMobileButton(dlink.createUploadButtonMobile());
		}
	} else {
		//template.find('.button-area').append(dlink.getDisabledDownloadButton());
		if (data.candownload) {
			dlink.addDesktopButton(dlink.getShareHeaderIcon());
			dlink.addDesktopButton(dlink.getDownloadHeaderButton());
		}
	}

	if (HFN.config.isMobile()) {
		grid_opts = {
			template: 'pubmobilefoldermixed',
			title: '',
			paging: 20000,
			templateswitch: [],
			noempty: true,
			code: code,
			linkpassword: linkpassword,
			canupload: canupload,
			usercanupload: usercanupload,
			candownload: candownload,
			place: '.relativePublink'
		}
	} else {
		grid_opts = {
			code: code,
			linkpassword: linkpassword,
			canupload: canupload,
			usercanupload: usercanupload,
			candownload: candownload,
			place: '.relativePublink'
		}
	}


	triggerOpenPublicFolder($.bbq.getState('folder') || meta.folderid, grid_opts).then((data) => {
		const blobid = _getParameterByName('blobid');
		_markFileBlobId(data, blobid);
	});

	$('.contents').addClass('gallery');

	_setupOnMobileResize();

	$(window).on('hashchange', openFolder);
	// remove resize
	/*
	if (HFN.config.isMobile()) {
		$(window).on('onmobileresize', _resizeMobile).trigger('onmobileresize');
	}
	*/

}

/**********************************/
/* 			  Helpers			  */
/**********************************/

function _resizeMobile() {
    var width = $(window).outerWidth();
    var peritem = 102;
    var max = $('.mobgriditem').length * 102;
    var minWidth = peritem * 3;
    $('.gridlist.publinkmobile').width(Math.max(minWidth, Math.trunc(width / peritem) * peritem));
}

function openFolder() {
	triggerOpenPublicFolder($.bbq.getState('folder') || meta.folderid, grid_opts);
	
	$('.contents').addClass('gallery');
}

function _markFileBlobId(data, blobid) {
	if (blobid != null) {
		var obj = _findByBlobid(meta, blobid, null);
		if(obj != null) {
			var parentFolderId = obj.parentfolderid;

			triggerOpenPublicFolder(parentFolderId, grid_opts).then(data => {
				$('.gridline .filename').filter(function( text ) {
				    return $( this ).text() === obj.name;
				}).closest('.gridline').css("background-color", "#ffff00");
			});
		}
	}
}

function _filterTree(meta) {
	_filterList(meta);
	for (var n = 0; n < meta.contents.length; ++n)
		if ('contents' in meta.contents[n])
			_filterList(meta.contents[n]);
}

function _filterList(meta) {
	var filtered = HFN._filterTree(meta.contents);
	if (filtered.length)
		meta.contents = filtered;
}

function _setupOnMobileResize() {
	$.mobile.orientationChangeEnabled = false;
	$(window).on('orientationchange', function () {
		$(window).trigger('onmobileresize');
		$(window).on('resize.tmp', function() {
			$(window).trigger('onmobileresize');
			$(window).off('resize.tmp');
		});
	});
}

function _getParameterByName (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function _findByBlobid(metadata, blob_id, result) {
    if(metadata instanceof Array) {
        for(var i = 0; i < metadata.length; i++) {
            result = _findByBlobid(metadata[i], blob_id, result);
        }
    }
    else
    {
        for(var prop in metadata) {
            console.log(prop + ': ' + metadata[prop]);
            if(prop == 'blobid') {
                if(metadata[prop] == blob_id) {
                    return metadata;
                }
            }
            if(metadata[prop] instanceof Object || metadata[prop] instanceof Array) {
                result = _findByBlobid(metadata[prop], blob_id, result);
            }
        }
    }
    return result;
}
