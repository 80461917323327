// @flow

import * as React from "react";
import $ from "jquery";
import browser from "../../lib/browser";
import OpenInApp from "../../components/OpenInApp";
import { componentRender } from "../../lib/componentMount";

import { getLocationFromUrl } from "../../lib/utils";

const deepLinks = {
  ios: "pcloud://savedownloadlink?code=%code%",
  android: ""
};

const storeLinks = {
  ios: "https://itunes.apple.com/app/pcloud/id692002098",
  android:
    "https://play.google.com/store/apps/details?id=com.pcloud.pcloud&referrer=utm_source?website&utm_medium?click"
};

export default function openOnApp(code: string) {
  if ($(".hidden-under").length) {
    return;
  }
  const cont = $("<div />").appendTo(document.body).addClass("hidden-under");

  const removeOpenApp = componentRender(
    cont[0],
    <OpenInApp
      onOpen={open(code)}
      onClose={_close}
    />
  );

  function _close() {
    cont.on("transitionend", () => {
      console.log("transition ended");
      if (removeOpenApp) {
        removeOpenApp();
      }
    });
    cont.removeClass("appear");
  }

  setTimeout(() => cont.addClass("appear"), 500);

  return () => _close();
}

export function canShow() {
  return browser.iphone || browser.ipad || browser.android;
}

function open(code) {
  const deepLink = getDeepLink(code);
  const storeLink = getStoreLink();
    
  return () => {
    if (typeof gtag === "function") {
      gtag("event", "shared_link_click", {
        action: "open in app"
      })
    }
    if (platform() === "ios") {
      //setTimeout(() => { window.location = storeLink; }, 500);
      window.location = deepLink;
    } else {
      window.location = deepLink;
      //window.location = "android-app://" + deepLink + "/" + storeLink;
    }
  }
}

function getDeepLink(code: string): string {
  const location = getLocationFromUrl() || {};
  const locationId = location.id || 2;
  return  "https://www.pcloud.com/app-save.html?code=" + code + "&locationid=" + locationId;
}

function getStoreLink(): string {
  return storeLinks[platform()];
}

function platform(): string {
  return browser.iphone || browser.ipad ? "ios" : "android";
}
