import dlink from "../";
import { isSameLocation, isInternetExplorer } from "../../utils";


export default function documentview(data, holder) {
	console.log("dlink documentview");
	var meta = data.metadata;
	var canDownload = data.candownload;
	var previewlink = data.previewlink + "?page=hsn#";
	var template;

	template = HFN.renderTemplate('#dLinkDocument').appendTo(holder);
	var pdfContainer = holder.find("#dLinkDocument-container")[0];

	if (canDownload) {
		if (isSameLocation()) {
			template.find('.button-area').append(dlink.getSaveButton());
		}
		template.find('.button-area').append(dlink.getDownloadButton());

		if (HFN.config.isMobile()) {
			dlink.addMobileButton(dlink.createShareButtonMobile());
			if (dlink._actionSheetDownloadItems().length !== 0) {
				dlink.addMobileButton(dlink.createDownloadButtonMobile());
			}
		}
	}
	//template.find('.button-area').append(dlink.getDisabledDownloadButton());

	$('html').addClass('document-preview document-loading');
	$('.header .filename').append(document.createTextNode(meta.name)).append(' <span>(' + HFN.formatSize(meta.size) + ')</span>');

	// local test with download restriction
	/*
	// run -> npm install http-server -g
	// create folder pdf local
	// add pdf in the folder
	// cd pdf
	// run -> http-server --cors pdf
	var node = holder.find("#dLinkDocument-container")[0];
	$('html').removeClass('document-loading');
	prc.DocPreview(node, {
		file: 'http://127.0.0.1:8080/NO%20BRANDING%20Getting%20started%20with%20pCloud.pdfGetting%20started%20with%20pCloud.pdfGetting%20started%20with%20pCloud.pdfGetting%20started%20with%20pCloud.pdfGetting%20started%20with%20pCloud.pdfGetting%20started%20with%20pCloud.pdfGetting%20started%20with%20pCloud.pdfGetting%20sta.pdf',
		onError: function () {
			dlink.go('generic');
		}
	});
	*/

	// Stop for local testing
	// /*
	checkSrc(previewlink, function () {
		$('html').removeClass('document-loading');
		if (!canDownload || isInternetExplorer() || $.browser.mobile) {
			template.find("iframe").hide();

			prc.DocPreview(pdfContainer, {
				file: previewlink,
				onError: function (error) {
					dlink.go('generic');
				}
			});
		} else {
			var iframeel = document.createElement("iframe");
			iframeel.setAttribute("src", previewlink);
			iframeel.setAttribute("frameborder", "0");
			iframeel.setAttribute("id", "dLinkDocument-iframe");
			pdfContainer.parentElement.appendChild(iframeel);
		}
	}, function (error) {
		dlink.go('generic');
	});
	// */

	//dlink.addDesktopButton(dlink.getShareHeaderButton());
	if (data.candownload) {
		dlink.addDesktopButton(dlink.getShareHeaderIcon());
		dlink.addDesktopButton(dlink.getDownloadHeaderButton());
	}
}

function checkSrc(iframeSrc, callback, errorCallback) {
	// debug
	// callback();
	// return; //stop for local testing

	// prod
	$.ajax({
		url: iframeSrc,
		cache: false,
		method: 'HEAD',
	}).done(function () {
		callback();
	}).fail(function () {
		errorCallback();
	});
}
