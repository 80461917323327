import dlink from "../";

// XZidPLZow1MrRAcJJuCra1cabPTKVxtfBO7

	var _resize = [];

	export default function password(data, holder) {
		var meta = data.metadata;
		var method = 'post';
		var iserror = '';
		var template;

		if (location.host == '127.0.0.1') {
			method = 'get';
		}

		var error = __('dl_password_protected', 'The content of this link is password protected.');
		if (typeof $.deparam.querystring().linkpassword === 'string' ||
			typeof $.deparam.querystring().ep === 'string') {
			error = __('dl_wrong_password', 'Wrong password.');
			iserror = 'wrong-password';
		}

		template = HFN.renderTemplate('#dLinkPasswordError', {
			method: method,
			code: dlink.code,
			error: error,
			customclass: iserror
		}).appendTo(holder);

		$(window).on('resize', resize).trigger('resize');
	}

	_resize.push(function () {
		dlink.centralizeDlContent('#dl_content .password');
	})

	function resize() {
		_resize.forEach(function (resize) {
			resize();
		});
	}
