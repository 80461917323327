import dlink from "./index";
import { CATEGORY } from "@pcloud/web-utilities/dist/config/constants";
import { rcookie } from "../utils";

var asiaCodes = [
	'SA', 'ID', 'CN', 'MM', 'KW', 'TH', 'AE', 'TR', 'MY', 'OM', 'IN', 'TW',
	'EG', 'SG', 'YE', 'QA', 'PH', 'JO', 'IQ', 'VN', 'SY', 'IL', 'PK'
];

function getAdTimes() {
	var times = 0;
	if (rcookie('ad-times')) {
		times = parseInt(rcookie('ad-times'));
	}

	return times;
}

function setAdTime() {
	setcookie('ad-times', getAdTimes() + 1);
}

function isAsia() {
	return (asiaCodes.indexOf(dlink.data.country.toUpperCase()) != -1 ||
		['XZ9kPuZ7UmbmhujfAFdFw41KksjUQEncHC7', 'XZTFiBZ0AGvm0aDhc7ahyC2qsw1QQ0DOUCy',
		 'XZ2e6uZvJw7eLxgI9muKXt5cD59vmXG5UaX', 'kZxUYXZO2KKG7hDh2FrjyJoV2lNAyItJxBk',
		 'XZK7guZ6PzAY9Li1q8oLxmsDLvPGpkV3rck', 'XZjQH2Z9oAJXbMpQT4mWbsxX1UnVVEPzBkV',
		 'XZG0nsZYALq95nK85B7iPCJS8mbBpkUz4yk', 'kZPSRBZbzYSrOcNujXXsc07r58R40crs4sy',
		 'XZz4DDZ3ExbUEFmzAHGFwF4MgTwLjNiONTy' // infolinks
		 ].indexOf(dlink.code) != -1);
}

function isMyanmar() {
	return dlink.data.country.toUpperCase() == 'MM' ||
		['XZjQH2Z9oAJXbMpQT4mWbsxX1UnVVEPzBkV'].indexOf(dlink.code) != -1;
}

function isThailand() {
	return dlink.data.country.toUpperCase() == 'TH' ||
		['XZK7guZ6PzAY9Li1q8oLxmsDLvPGpkV3rck'].indexOf(dlink.code) != -1;
}

function isApk() {
	return dlink.data && dlink.data.metadata && fileext(dlink.data.metadata.name).toLowerCase() == 'apk';
}

function isError() {
	return dlink._view === "error";
}

function isVideo() {
	return ['video', 'folder'].indexOf(dlink._view) != -1;
}

function isOvertraffic() {
	return ['overtraffic'].indexOf(dlink._view) != -1;
}

function isNonVideo() {
	return !isVideo();
}

function getCountryCode() {
	return dlink.data.country.toUpperCase();
}

function isPropeller() {
	return ['XZcXfsZP99e9XPwRgpIUM4DyLpIt4IlBTNX'].indexOf(dlink.code) != -1;
}

function isMaven() {
	return false;
	return ['XZG0nsZYALq95nK85B7iPCJS8mbBpkUz4yk'].indexOf(dlink.code) != -1;
}

function isInfolinks() {
	return ['XZz4DDZ3ExbUEFmzAHGFwF4MgTwLjNiONTy'].indexOf(dlink.code) != -1;
}

function gaEvent(adType) {
	if (adType) {
		// gaAll('event', 'ad-2016', 'show-' + ($.browser.mobile ? 'mobile' : 'desktop') + '-' + dlink._view + '-' + adType, dlink.data.country.toUpperCase());
		// if (typeof ga4 == 'function') {
		// 	ga4({
		// 		eventname: "view_promotion",
		// 		ecommerce: {
		// 			items: [{
		// 				promotion_name: "AD 2016 - " + dlink._view + ($.browser.mobile ? ' mobile' : ' desktop'),
		// 				creative_slot: "button",
		// 				item_brand: "pCloud",
		// 				item_id: getPromoParam("channelid"),
		// 				affiliation: rcookie("ref")
		// 			}]
		// 		}
		// 	});
		// }
	}
}

function gaClick(adType) {
	if (adType) {
		// gaAll('event', 'ad-2016', 'click-' + ($.browser.mobile ? 'mobile' : 'desktop') + '-' + dlink._view + '-' + adType, dlink.data.country.toUpperCase());
		// if (typeof ga4 == 'function') {
		// 	ga4({
		// 		eventname: "select_promotion",
		// 		ecommerce: {
		// 			items: [{
		// 				promotion_name: "AD 2016 - " + dlink._view + ($.browser.mobile ? ' mobile' : ' desktop'),
		// 				creative_slot: "button",
		// 				item_brand: "pCloud",
		// 				item_id: getPromoParam("channelid"),
		// 				affiliation: rcookie("ref")
		// 			}]
		// 		}
		// 	});
		// }
	}
}

function viewReferral() {
	var defaultRef = 1098;
	var platform = $.browser.mobile ? 'mobile' : 'desktop';
	var refs = {
		mobile: {
			image: 1099,
			video: 1100,
			document: 1101,
			folder: 1102,
			audio: 1103,
			generic: 1104

			// overtraffic
			// error
		},
		desktop: {
			image: 1105,
			video: 1106,
			document: 1107,
			folder: 1108,
			audio: 1109,
			generic: 1110

			// overtraffic
			// error
		}
	}

	return dlink._view in refs[platform] ? refs[platform][dlink._view] : defaultRef;
}

// only video
var ads = {
	desktop: {
		video: {
			noop: true,
			url: 'https://www.pcloud.com/encrypted-cloud-storage.html?ref=666',
			image: '2.png'
		},
		folder: {
			url: 'https://www.pcloud.com/cloud-storage-pricing-plans.html?freebox=off&features=on&freelink=on&ref=666',
			image: '6.png'
		}/*,
		overtraffic: {
			url: 'https://www.pcloud.com/cloud-storage-pricing-plans.html?freebox=off&features=on&freelink=on&ref=666',
			image: '6.png'
		}*/
	},
	mobile: {
		video: {
			url: 'https://www.pcloud.com/encrypted-cloud-storage.html?ref=666',
			image: 'kee-video-secure.png',
			imageSize: '154px 117px',
			text: 'Keep your videos secure and encrypt them in the cloud',
			buttonText: 'Get pCloud Crypto'
		},
		folder: {
			url: 'https://www.pcloud.com/cloud-storage-pricing-plans.html?freebox=off&features=on&freelink=on&ref=666',
			image: 'never-lose-file.png',
			imageSize: '154px 117px',
			text: 'Never lose a file with a pCloud Premium plan',
			buttonText: 'Order Now'
		}/*,
		overtraffic: {
			url: 'https://www.pcloud.com/cloud-storage-pricing-plans.html?freebox=off&features=on&freelink=on&ref=666',
			image: 'never-lose-file.png',
			imageSize: '154px 117px',
			text: 'Never lose a file with a pCloud Premium plan',
			buttonText: 'Order Now'
		}*/
	}
};

for (var n in ads.desktop) {
	if (n == 'video') {
		ads.desktop[n] = {
			noop: true,
			url: 'registration',
			image: '7.png'
		};
	} else {
		ads.desktop[n] = {
			url: 'registration',
			image: '7.png'
		};
	}
}

for (var n in ads.mobile) {
	if (n == 'video') {
		ads.mobile[n] = {
			url: 'registration',
			image: 'video.png',
			imageSize: '245px 245px',
			imagePosition: 'top 10px center',
			imageHolderSize: { width: '300px', height: '245px' },
			text: 'Save this video in pCloud so you can watch it later.',
			buttonText: 'Create Free Account',
			smallVersion: {
				image: '7-small.gif'
			}
		};
	} else {
		ads.mobile[n] = {
			url: 'registration',
			image: 'general.png',
			imageSize: '245px 245px',
			imagePosition: 'top 10px center',
			imageHolderSize: { width: '300px', height: '245px' },
			text: 'Download in your FREE pCloud account',
			buttonText: 'Create Free Account',
			smallVersion: {
				image: '8-small.gif'
			}
		};
	}
}

function show(data) {
	var meta = data.metadata;

	dlink.getUser(function(user) {
		if (_shouldShowAds(user, data)) {
			if ($.browser.mobile) {
				return _showMobile(_getAd(meta));
			} else {
				return _showDesktop(_getAd(meta));
			}
		}
	});
}

function _willShowAd(cb) {
	dlink.getUser(function(user) {
		cb(_shouldShowAds(user));
	});
}

function _canShowAds(user) {
	var data = dlink.data;
	var meta = data.metadata;
	var type = getType(meta);

	if (dlink._view === 'password') {
		return false;
	}

	if ($.browser.mobile && !isPortrait()) {
		return false;
	}

	if (rcookie('no-ad2016')) {
		return false;
	}

	if (data.ownerispremium && !data.showads) {
		return false;
	}

	if (data.ownerisbusiness) {
		return false;
	}

	if (!data.candownload) {
		return false;
	}

	return true;
	//return !user || (!user.premium && !user.business);
}

function _canShowExternalAds(user) {
	var ret = false;

	if (isInfolinks()) {
		ret = true;
	} else if (isOvertraffic()) {
		if (isAsia()) {
			ret = true; // adsense
		}

		// no ads for overtraffic
		ret = false;
	} else if (isVideo()) {
		ret = false; // infolinks
	} else if (isNonVideo()) {
		ret = true; // adsense
	} else if (isError()) {
		ret = true; // adsense
	}

	if (user && (user.premium || user.business)) {
		ret = false;
	}

	return ret;
}

function _canShowInternalAds(user) {
	var data = dlink.data;
	var meta = data.metadata;
	var type = getType(meta);

	console.log(ads.mobile);
	console.log(ads.desktop);

	if ($.browser.mobile && !(type in ads.mobile)) {
		return false;
	} else if (!$.browser.mobile && !(type in ads.desktop)) {
		return false;
	}

	console.log('user');
	console.log(user);

	return !user;
	//return (!user || (!user.premium && !user.business));
}

function _shouldShowAds(user) {
	console.log('_canShowAds', _canShowAds(user));
	console.log('_canShowExternalAds', _canShowExternalAds(user));
	console.log('_canShowInternalAds', _canShowInternalAds(user));

	return _canShowAds(user) && (_canShowExternalAds(user) || _canShowInternalAds(user));
}

function _showMyanmarAdDesktop(cont) {
	cont.append('<div id="ros-728x90-1"></div>');
	cont.on('ready', function() {
		googletag.cmd.push(function() {
			googletag.display('ros-728x90-1');
		});
	});

	gaEvent('myanmar');
}

function _showAsiaAdDesktop(cont) {
	console.log('data', dlink.data);

	if (0) {
		_showAdKodeDesktop(cont);
	} else {
		_showAdsenseDesktop(cont);
	}
}

function _showAdKodeDesktop(cont) {
	cont.append('<iframe style="display:block !important" frameborder=0 marginwidth=0 marginheight=0 scrolling=no width=728 height=90 allowtransparency=true src="//s.ato.mx/p.html#id=228369&size=728x90"></iframe>');

	gaEvent('adkode');
}

function _showAdsenseDesktop(cont) {
	cont.append('<ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-4722876883225021" data-ad-slot="3568358598" data-ad-format="auto"></ins>');
	cont.find('.message-close').remove();
	cont.on('ready', function() {
		(adsbygoogle = window.adsbygoogle || []).push({});
	});

	gaEvent('adsense');
}

function adzoukDesktop(cont) {
	return '<iframe src="//ads.pcloud.com/?id=4" width="728" height="90" frameborder="0" scrolling="no" />';
}

function infoLinks() {
	window.infolinks_pid = 2867830;
	window.infolinks_wsid = 0;

	$('head').append('<script type="text/javascript" src="//resources.infolinks.com/js/infolinks_main.js"></script>');

	gaEvent('infolinks');
}

function _showDesktop(ad) {
	if (!isVideo() && !isOvertraffic()) {
		adsenseDesktopAd();
	} else if (isError()) {
		adsenseDesktopAd();
	} else if (ad) {
		pCloudDesktopAd(ad);
	}
}

function _showMyanmarAdMobile() {
	cont = $('<div id="ros-728x90-1"></div>').appendTo(document.body);
	googletag.cmd.push(function() {
		googletag.display('ros-728x90-1');
	});

	gaEvent('myanmar');
}

function _showAsiaAdMobile(cont) {
	if (0 && !isApk()) {
		if (Math.random() * 2000 > 1000 && 0) {
			$('.falconAd').show();
		} else {
			$(document.body).prepend('<script src="//go.mobtrks.com/notice.php?p=740874&interstitial=1"></script>');
		}
	} else {
		setAdTime();

		if (getAdTimes() > 1)  {
			_showAdsenseMobileOverlay(cont);
		} else {
			_showAdsenseMobileBanner();
		}
	}
}

function adsenseMobileOverlayBanner() {
	gaEvent('adsense-overlay');

	if (isOvertraffic()) {
		return '<iframe frameborder=0 marginwidth=0 marginheight=0 scrolling=no width=300 height=250 allowtransparency=true src="https://ads.pcloud.com/?id=11"></iframe>';
	} else {
		return '<iframe frameborder=0 marginwidth=0 marginheight=0 scrolling=no width=300 height=250 allowtransparency=true src="https://ads.pcloud.com/?id=8"></iframe>';
	}
}

function adsenseMobileBanner() {
	gaEvent('adsense-banner');

	if (isOvertraffic()) {
		return '<div class="adsense-banner"><iframe frameborder=0 marginwidth=0 marginheight=0 scrolling=no width="300" height=90 allowtransparency=true src="https://ads.pcloud.com/?id=12"></iframe></div>';
	} else {
		return '<div class="adsense-banner"><iframe frameborder=0 marginwidth=0 marginheight=0 scrolling=no width="300" height=90 allowtransparency=true src="https://ads.pcloud.com/?id=9"></iframe></div>';
	}
}

function adsenseDesktopBanner() {
	gaEvent('adsense-banner');

	if (isOvertraffic()) {
		return '<iframe frameborder=0 marginwidth=0 marginheight=0 scrolling=no width=728 height=90 allowtransparency=true src="https://ads.pcloud.com/?id=10" style="margin:0 auto;"></iframe>';
	} else {
		return '<iframe frameborder=0 marginwidth=0 marginheight=0 scrolling=no width=728 height=90 allowtransparency=true src="https://ads.pcloud.com/?id=7" style="margin:0 auto;"></iframe>';
	}
}

function _showAdsenseMobileOverlay(cont) {
	cont.find('.message-inner-image')
		.append('<ins class="adsbygoogle" style="display:block;width:300px;height:250px;" data-ad-client="ca-pub-4722876883225021" data-ad-slot="2056965792" data-ad-format="auto"></ins>');

	cont.on('ready', function(e) {
		(adsbygoogle = window.adsbygoogle || []).push({});
	});

	gaEvent('adsense-overlay');
}

function adzoukMobileBanner() {
	gaEvent('adzouk');
	return '<iframe style="display:inline-block !important" frameborder=0 marginwidth=0 marginheight=0 scrolling=no width=300 height=250 allowtransparency=true src="//ads.pcloud.com/?id=5"></iframe>';
}

function _showMobile(ad) {

	// test cases
	if (isMaven()) {
		$('head').append('<script data-cfasync="false" src="//dymwsh1c2kslr.cloudfront.net/?swmyd=616934"></script>');
		return;
	}

	if (!isVideo() && !isOvertraffic()) {
		adsenseMobileAd();
	} else if (isError()) {
		adsenseMobileAd();
	} else {
		pCloudMobileAd(ad);
	}
}

function _adImagePath(src) {
	return retinaSrc('/ZCDNURL/ZREPLACETHISWITHVERSION/img/mobile/ads-2016/' + src);
}

function _getAd(meta) {
	var type = getType(meta);
	var browserType = $.browser.mobile ? 'mobile' : 'desktop';

	console.log('browserType', browserType);
	console.log('type', type);

	if (browserType in ads) {
		if (type in ads[browserType]) {
			return ads[browserType][type];
		}
	}

	return false;
}

var categoryAdsMap = {};
categoryAdsMap[CATEGORY.IMAGE] = 'image';
categoryAdsMap[CATEGORY.VIDEO] = 'video';
categoryAdsMap[CATEGORY.AUDIO] = 'audio';
categoryAdsMap[CATEGORY.DOCUMENT] = 'document';
categoryAdsMap[CATEGORY.ARCHIVE] = 'archive';

function getType(meta) {
	if (dlink._view == 'generic') {
		if (meta.category == CATEGORY.DOCUMENT) {
			return 'document';
		}
	}

	return dlink._view;

	/*
	if (meta.isfolder) {
		type = 'folder';
		//if (isManyPhotos(meta))
		//	type = 'folder-photos';
	} else if (meta.category in categoryAdsMap) {
		type = categoryAdsMap[meta.category];
	} else {
		type = 'generic';
	}

	return type;
	*/
}

/*****************************/

function adsenseDesktopAd() {
	return;

	return withDesktopOverlay(adsenseDesktopBanner());
}

function adzoukDesktopAd() {
	gaEvent('adzouk');
	return withDesktopOverlay(adzoukDesktop())
}

function pCloudDesktopAd(ad) {
	gaEvent('pcloud-banner');
	return withDesktopOverlay(pCloudAdContent(ad));
}

function pCloudAdContent(ad) {
	var cont;

	cont = $('<a></a>')
		.attr('href', 'javascript:;')
		.attr('target', '_self')
		.append(retinaImage('/ZCDNURL/ZREPLACETHISWITHVERSION/img/mobile/ads-2016/' + ad.image, 728, 90));

	if (ad.url == 'registration') {
		cont.on('click', function(e) {
			gaClick('pcloud-banner');
			setcookie('ref', viewReferral(), 0);
			HFN.initLoginRegModal('register', function() {
				dlink._actionCopyToCloud();
			});
		});
	} else {
		cont.on('click', function() {
			gaClick('pcloud-banner');
			setcookie('ref', viewReferral(), 0);

			setTimeout(function() {
				location.href = ad.url;
			}, 200);
		});
	}

	return cont;
}

function withDesktopOverlay(adHtml) {
	var cont = HFN.renderTemplate('#desktopMessageAd').addClass('ad');

	cont.find('.message-img').append(adHtml);
	cont.find('.message-close').on('click', function(e) {
		$('.desktop-message').animate({
			bottom: '-500px'
		}, 200, function() {
			$('.desktop-message').remove();
		});
	});

	$(document.body).append(cont.hide());

	setTimeout(function() {
		cont.show();
		cont.trigger('ready');
	}, 1200);
}

/// mobile ///

function adsenseMobileAd() {
	return;

	setAdTime();

	if (getAdTimes() > 50000) {
		return withMobileOverlay(adsenseMobileOverlayBanner());
	} else {
		return withAppend(adsenseMobileBanner());
	}
}

function adzoukMobileAd() {
	return withMobileOverlay(adzoukMobileBanner());
}

function pCloudMobileAd(ad) {
	setAdTime();

	if (getAdTimes() > 2) {
		gaEvent('pcloud-overlay');
		//return withMobileOverlay(pCloudMobileOverlayContentBlobk(ad));
		return withMobileOverlay(pCloudMobileOverlayContent(ad));
	}
}

function pCloudMobileOverlayContentBlobk(ad) {
	var anchor =
		$('<a></a>')
			.attr('href', 'javascript:;');
	var url = ad.url;

	if (ad.url == 'registration') {
		url = '/#page=login' +
			'&memo=' + encodeURIComponent('Login to save') +
			'&returl=' + encodeURIComponent(dlink._location() + '&copynow=1');
	}

	anchor.on('click', function(e) {
		gaClick('pcloud-banner');
		setcookie('ref', viewReferral(), 0);

		setTimeout(function() {
			location.href = url;
		}, 200);
	});

	return $('<div class="pcloud-banner-block"></div>')
		.append(
			anchor.append(
				$('<img width="300" height="250" />')
					.attr('src', _adImagePath(ad.image))
			)
		);
}

function pCloudMobileOverlayContent(ad) {
	var cont = HFN.renderTemplate('#pcloudMessage');

	console.log('===');
	console.log(ad);
	console.log('===');

	cont/*.find('.message-inner')*/
		.css({
			'background-image': 'url(' + _adImagePath(ad.image) + ')',
			'background-size': ad.imageSize
		});

	if (ad.imagePosition) {
		cont/*.find('.message-inner')*/.css('background-position', ad.imagePosition);
	}

	if (ad.imageHolderSize) {
		cont.find('.message-inner-image').css(ad.imageHolderSize);
	}

	cont.find('.message-inner-text').text(ad.text);

	var anchor = $('<a></a>')
		.text('Get pCloud Crypto')
		.attr('href', 'javascript:;')
		.text(ad.buttonText);
	var url = ad.url;

	if (ad.url == 'registration') {
		url = '/#page=login' +
			'&memo=' + encodeURIComponent('Login to save') +
			'&returl=' + encodeURIComponent(dlink._location() + '&copynow=1');
	}

	anchor.on('click', function() {
		gaClick('pcloud-overlay');
		setcookie('ref', viewReferral(), 0);
		setTimeout(function() {
			location.href = url;
		}, 300);
	});

	cont.find('.message-inner-button').append(anchor);

	return cont;
}

function withAppend(adHtml) {
	$(document.body).append(adHtml);
}

function withMobileOverlay(adHtml) {
	var cont = HFN.renderTemplate('#mobileMessageAd');

	cont.find('.message-spot').append(adHtml);
	cont.find('.message-close').on('click', _close);
	setTimeout(_appear, 1000);

	/*
	cont.find('.message-spot').css({
		position: 'absolute',
		top: '50%',
		left: '50%',
		'margin-top': -1 * cont.find('.message-spot').outerHeight() / 2,
		'margin-left': -1 * cont.find('.message-spot').outerWidth() / 2
	});
	*/

	function _appear() {
		$(document.body).append(cont);
		_center(cont);
		_overlay();

		setTimeout(function () {
			cont.addClass('show');
			cont.trigger('ready');
		}, 200);

		$(window).on('orientationchange', function (e) {
			_close();
		});
	}

	function _close() {
		//setcookie('no-ad2016', 1, 0);
		$('.mobile-message').remove();
		_closeOverlay();
	}

	function _center() {
		/*
		cont.find('.message-inner').css({
			'margin-top': ($(window).outerHeight() - $('.message-inner').outerHeight() - 20) / 2
		});
		*/

		cont.find('.message-spot').css({
			'margin-top': ($(window).outerHeight() - $('.message-spot').outerHeight()) / 2
		});

		/*
		return;
		cont.css({
			top: '50%',
			left: '50%',
			'margin-top': cont.outerHeight() / 2 * -1,
			'margin-left': cont.outerWidth() / 2 * -1
		});
		*/
	}

	function _closeOverlay() {
		$('body > .mobile-overlay').remove();
	}

	function _overlay() {
		var overlay;

		overlay = $('<div class="mobile-overlay"></div>')
			.appendTo(document.body);

		setTimeout(function () {
			overlay.addClass('show');
		}, 5);
	}
}

/*****************************/

export default { show: show, willShowAd: _willShowAd };
