export default function audio(data, holder) {
	let params = {};
	if (data.metadata.isfolder) {
		const songs = data.metadata.contents;
		songs.forEach((item, index) => {
			songs[index].code = data.metadata.code;
		})
		params = {
			list: HFN.audioPlayer.getMinifyData(songs),
			playItemId: songs[0].fileid
		};
	} else {
		const song = data.metadata;
		song.code = data.code;
		params = {
			list: HFN.audioPlayer.getMinifyData([song]),
			playItemId: song.fileid
		}
	}

	params.data = data;
	params.isPublic = true;
	params.isSingle = true;

	const footer = document.getElementsByTagName('footer')[0];

	if (footer)
		footer.style.display = 'none';

	if (HFN.config.isMobile()) {
		document.documentElement.style.height = '100%';
		document.body.style.height = '100%';
		document.getElementById('megawrap').style.height = '100%'
	}

	this.audioPlayerBox = prc.AudioPlayer(holder[0], params);
}